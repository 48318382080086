
@import url('https://fonts.googleapis.com/css2?family=Faustina:ital,wght@0,300;0,400;1,300;1,400&display=swap');
body{
  font-family: 'Faustina', serif;
}
.App {
  text-align: center;
}


/* .................................................................................. */
.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
}

.image-item {
  width: 100%;
  height: 100%;
}

.image-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}



/***********************************Image Upload ****************************************/

.upload-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.upload-form {
  display: flex;
  gap: 10px;
}

.upload-form input[type="text"] {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.upload-form button {
  padding: 8px 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.upload-form button:hover {
  background-color: #45a049;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 10px;
}

.image-item {
  position: relative;
}

.delete-button {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 30px;
  height: 30px;
  background-color: #ff5c5c;
  color: white;
  border: none;
  border-radius: 50%;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
}

.delete-button:hover {
  background-color: #ff3f3f;
}


/******************************** Home card  ***************************/
.card1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #fff;
}

.circle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.text-container {
  margin-bottom: 20px;
}






/* *************************************************************************** */

.features-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.feature-box {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.feature-item {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.features-content button {
  margin-top: 20px;
}




